<template>
  <div class="initiate-view">
    <tojoy-title-content title="我发起的">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
        </div>
      </template>
    </tojoy-title-content>
    <tojoy-pagination-list
      :data="listData"
      ref="tojoy-pagination-list-ref"
      @page-change="handleChangePage"
      schema="block"
      :total="total"
      :loading="loading"
    >
      <template #item="{data}">
        <card :data="data" type="initiate" :moduleName="moduleName">
          <template #num>
            <span class="num">{{ data.finalScore | formateFinalScore(data.processState) }}</span>
          </template>
          <template #status>
            <span class="status" :class="`status-${data.processState}`">{{
              data.processName | formateProcessState(data.approverName, data.processState)
            }}</span>
          </template>
        </card>
      </template>
    </tojoy-pagination-list>
  </div>
</template>

<script>
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyPaginationList from '@/components/business/pagination-list'
import card from '@/components/initiate/card'
import { getMeSponsor, getListCount } from '@/api/api_innovation'
import tabsRouteMixins from '@/mixins/tabsRouteMixins'
import listMixin from '@/mixins/initiate/listMixin.js'
import TojoyTabs from '@/components/business/tabs'
export default {
  name: 'initiate',
  mixins: [listMixin, tabsRouteMixins],
  components: {
    card,
    TojoyTitleContent,
    TojoyPaginationList,
    TojoyTabs
  },
  props: {
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {}
  },
  created() {
    this.getCount()
    let obj = {
      all: 0,
      submit: 1,
      approval: 2,
      through: 3
    }
    this.tabIndex = obj[this.moduleName]
    this.loading = true
    this.getList()
  },
  methods: {
    getCount() {
      getListCount({ innovateState: 0 })
        .then(res => {
          //待提交 toBeSubmitted,审批中 underReview
          let { toBeSubmitted: submit, underReview: approval } = res.data
          this.$set(this.tabs[1], 'badge', submit)
          this.$set(this.tabs[2], 'badge', approval)
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleChangePage(val) {
      this.page = val
      this.loading = true
      this.getList()
    },
    getList(state = 0) {
      let params = {
        page: this.page,
        size: this.size,
        innovateState: state || this.tabIndex,
        companyId: 0
      }
      getMeSponsor(params)
        .then(res => {
          let { list, total } = res.data
          this.listData = list
          this.listData.forEach(el => {
            this.$set(el, 'companyName', el.implementOrgName)
          })
          this.total = total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleTabClick({ index }) {
      this.tabIndex = index
      this.listData = []
      this.total = 0
      this.page = 1
      this.size = 10
      this.loading = true
      this.$refs['tojoy-pagination-list-ref'].resetScrollTop()
      this.getList(index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/approval.scss';
</style>
